/*export class MemberDetailwebrequest {
    carrierId!:string;
    accountId!:string;
    groupId!:string;
    memberId!:string;
    instanceId!:string;
}*/

export class MemberDetailWebRequesthemi {
    carrierId!:string;
    accountId!:string;
    groupId!:string;
    id!:string;
    sourceSystemInstance!:string;
}



