<div class="row justify-content-center mb-5">
    <div class="col-md-8 col-sm-8 col-xs-12 timelinetitle row mt-3">
        <div class="col">
            <p class="fs-2 fw-lighter text-capitalize">{{this.memberSearchResult!.firstName.toLocaleLowerCase()}}
                {{this.memberSearchResult!.lastName.toLocaleLowerCase()}}</p>
        </div>
        <div class="col mb-2">
            <a class="underline float-end fontbold mt-3 rounded-pill"  tabindex="0" (click)="gotoSelectPrescriber()" (keydown.enter)="gotoSelectPrescriber()" style="cursor: pointer;">Submit new/
                additional claim
            </a>
        </div>
    </div>
    <div class="col-md-8 col-sm-8 col-xs-12 shadow mb-5 row p-3 bg-white" *ngIf="this.memberSearchResult!=undefined">
        <div class="col row text-muted">
            <label class="fontbold p-2" style="color: #444444;">Date Of birth</label>
            <label class="text-dark" style="color: #636363;">{{this.memberSearchResult!.dateOfBirth}}</label>
        </div>
        <div class="col row text-muted">
            <label class="fontbold p-2" style="color: #444444;">Gender</label>
            <label class="text-dark" style="color: #636363;">{{this.gender}}</label>
        </div>
        <div class="col row text-muted">
            <label class="fontbold p-2" style="color: #444444;">Phone number</label>
            <label class="text-dark" *ngIf="this.memberDetailResult!=undefined" style="color: #636363;">{{this.memberDetailResult!.memberItems[0]!.contact.phoneNumber}}</label>
        </div>
        <div class="col row text-muted">
            <label class="fontbold p-2" style="color: #444444;">Address</label>
            <label class="text-dark text-capitalize" style="color: #636363;">{{this.memberSearchResult!.address.address1.toLocaleLowerCase()}},
                {{this.memberSearchResult!.address.address2.toLocaleLowerCase()}}{{this.memberSearchResult!.address.address3.toLocaleLowerCase()}}
                {{this.memberSearchResult!.address.city.toLocaleLowerCase()}},
                {{this.memberSearchResult!.address.state}} {{this.memberSearchResult!.address.zip1}}</label>
        </div>
    </div>

    <div class="mt-2 col-md-8 col-sm-8 col-xs-12 mb-5 shadow">
        <div class="p-3 bg-white">
            <div class="row">
                <div class="col">
                    <h4 class="mt-3 mb-3 col">Insurance information</h4>
                </div>
                <div class="col">
                    <!-- <a class="float-end btn invisiblebtn" name="refresh" enableAction="true">
                        <i class="fa fa-refresh fs-3 fa-spin-hover" style="color: #316BBE;" name="refresh" enableAction="true" ria-hidden="true" [ngClass]="{'rotateRefresh':(data.animate)}"></i>&nbsp;&nbsp;
                        Refresh eligibility status
                    </a> -->
                    <button class="underline font-bold mt-3 mb-3 float-end fontbold col" (click)="this.refreshMemberDetails()">
                        <i class="fa fa-refresh fs-3 fa-spin-hover" style="color:#316BBE;" aria-hidden="true" [ngClass]="{'rotateRefresh':(this.refreshData)}"></i>&nbsp;&nbsp;
                        Refresh eligibility status
                    </button>
                </div>
            </div>
        </div>
        <div class="m-0 p-3 bg-white border-2  border-top row">
            <div class="row">
                <div class="col row text-muted">
                    <label class="fontbold p-2" style="color: #444444;">Policy status</label>
                    <label class="text-dark" style="color: #636363;"><span _ngcontent-vyo-c126=""
                            *ngIf="this.memberSearchResult!.activeInd=='A'"
                            class="alert alert-success text-capitalize resultsflag">Active</span>
                        <span _ngcontent-vyo-c126="" *ngIf="this.memberSearchResult!.activeInd=='I'"
                            class="alert alert-warning text-capitalize resultsflag">Active</span>
                    </label>
                </div>
                <div class="col row text-muted">
                    <label class="fontbold p-2" style="color: #444444;">Dates eligible</label>
                    <label class="text-dark" style="color: #636363;">{{this.eligibilityDates}}</label>
                </div>
                <div class="col row text-muted">
                    <label class="fontbold p-2" style="color: #444444;">Last updated</label>
                    <label class="text-dark" style="color: #636363;">{{this.lastUpdated}}</label>
                </div>
            </div>
        </div>

        <div class="m-0 p-3 border-1 border-top row" style="background-color: rgba(242,246,249,1);">
            <div class="row">
                <div class="col row text-muted">
                    <label class="fontbold p-2" style="color: #444444;">MemberId</label>
                    <label class="text-dark" style="color: #636363;">{{this.memberSearchResult?.memberId}}</label>
                </div>
                <div class="col row text-muted">
                    <label class="fontbold p-2" style="color: #444444;">Group ID</label>
                    <label class="text-dark" style="color: #636363;">{{this.memberSearchResult?.groupId}}</label>
                </div>
                <div class="col row text-muted">
                    <label class="fontbold p-2" style="color: #444444;">Relationship</label>
                    <label class="text-dark" style="color: #636363;">{{this.relationshipCode}}</label>
                </div>
                <div class="col row text-muted">
                    <label class="fontbold p-2" style="color: #444444;">Person code</label>
                    <label class="text-dark" style="color: #636363;">{{this.memberSearchResult?.personCode}}</label>
                </div>
                <!-- <div class="col"></div> -->
            </div>
        </div>
    </div>


    <div class="mt-2 col-md-8 col-sm-8 col-xs-12 mb-5 shadow">
        <div class="p-3 bg-white">
            <button class="btn btn-secondary float-end rounded-pill" (click)="gotoAllSubmittedClaims()">View all
                claims</button>
            <h2>Claim status</h2>
            <p class="mt-2 large">Up to ten of the most recent claims submitted for this patient are shown below.
                <br />If the claim status looks incorrect, click the refresh icon to update it in real time.
            </p>
        </div>
        <savedclaimhistory [isHomePage]="true" [pageType]="3" [memberId]="this.memberSearchResult!.memberId" [showStatus]="true">
        </savedclaimhistory>
    </div>

    <div class="mt-5 col-md-8 col-sm-8 col-xs-12 mb-5 shadow">
        <!-- <div class="col-lg-2 .d-md-none .d-sm-none"></div> -->
            <div class="row mt-5">
                <!-- <div class="col-lg-2 .d-md-none .d-sm-none"></div> -->
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="tabs-header">
                        <ul class="nav nav-tabs text-center">
                            <li class="nav-item">
                              <a class="nav-link" [ngClass]="{'active':activeTab==1}" (click)="activeTab=1" href="javascript:void(0)" id="pahistory" role="tab">Prior authorization history</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" [ngClass]="{'active':activeTab==2}" (click)="activeTab=2" href="javascript:void(0)" id="savedpahistory" role="tab">Prior authorizations in progress</a>
                            </li>
                          </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-lg-2 .d-md-none .d-sm-none"></div> -->
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="tab-content">
                        
                        <div class="tab-pane fade show active" *ngIf="activeTab==1" role="tabpanel" aria-labelledby="pahistory">
                            <button class="btn btn-secondary float-end rounded-pill" (click)="gotoViewAllSubmittedPA()">View all  PA history</button>
                            <h2>Prior authorization history</h2>
                            <p class="large">Below are the most recent saved prior authorizations within the past 30 days.</p>
                            <section>
                                <!-- <submittedpahistory [memberId]="this.memberSearchResult!.memberId" [maxResults]="maxHistoryCount"></submittedpahistory> -->
                                <membersubmittedpahistory [memberId]="this.memberSearchResult!.memberId" [maxResults]="maxHistoryCount"></membersubmittedpahistory>
                            </section>
                        </div>
                        <div class="tab-pane fade show active" *ngIf="activeTab==2" role="tabpanel" aria-labelledby="savedpahistory">
                            <button class="btn btn-secondary float-end rounded-pill" (click)="gotoViewAllSavedPA()">View all PAs in progress</button>
                            <h2>Prior authorizations in progress</h2>
                            <p class="large">Below are the most recent saved prior authorizations within the past 30 days.</p>
                            <section class="pb-4">
                                <savedpahistory [memberId]="this.memberSearchResult!.memberId" [pageType]="this.memberSpecific" [maxResults]="maxHistoryCount"></savedpahistory>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 text-start backToHome">
            <i class="fa fa-angle-left"></i>
            &nbsp;
            <a class="btn btn-link backToHome" (click)="goto('searchMediactionClaims')" tabindex="0">
                Back to member search
            </a>
        </div>
    </div>

    <ng-template #errorPopup let-modal>
        <div class="p-3" aria-modal="true" aria-labelledby="errorPopup" role="dialog">
            <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
            <div class="text-center mt-2">
                An error occured. Please try again.
            </div>
            <div class="row border-0 mt-4 p-3 ">
                    <button class="col-2  position-absolute top-80 start-50 translate-middle btn btn btn-primary" (click)="modal.dismiss()">OK</button>
            </div>
        </div>
    </ng-template>