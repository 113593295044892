import { Component, Input, OnInit, Output, EventEmitter, INJECTOR, ElementRef, ViewChild, ContentChild } from '@angular/core';
import { ClaimSummaryDetails } from 'src/app/modals/claimsummary/claimsummary';
import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fade, listAnimation, slideDown, explainerAnim } from 'src/animations/animations';
import { CheckCoveragePageService } from 'src/app/services/drugSearch/check-coverage-page.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Observable, Subscription, } from 'rxjs';
//import { ClaimSummaryComponent } from 'src/app/components/claim-summary/claim-summary.component';
import { ClaimsummaryComponent } from 'src/app/components/claimsummary/claimsummary.component';
import { CalculateQuantityService } from 'src/app/services/Quantity/calculate-quantity.service';
import { PRESCRIBERDETAIL_STATUS, EPA_STATUS, DUR_PROFESSIONAL_SERVICECODES, DUR_RESULTOF_SERVICECODES } from 'src/app/constants/appconstant';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { PARefIDWebResponse } from 'src/app/modals/epa/priorauthresponse';
import { EpaRunnerInitiationEvent } from 'src/app/modals/epa/priorauthrequest';
import { LoggerService } from 'src/app/services/logger/logger.service';
//import { MemberDetailwebrequest } from 'src/app/modals/membersearch/memberdetailwebrequest';
//import { MemberDetailWebResponse } from 'src/app/modals/membersearch/memberdetailwebresponse';
import { MemberDetailWebResponsehemi } from 'src/app/modals/membersearch/memberdetailresponsehemi';
import { MemberDetailWebRequesthemi } from 'src/app/modals/membersearch/memberdetailwebrequest';


@Component({
  selector: 'app-payble-claim-summary',
  templateUrl: './payble-claim-summary.component.html',
  styleUrls: ['./payble-claim-summary.component.css'],
  animations: [
    fade,
    listAnimation,
    slideDown,
    explainerAnim

  ]
})
export class PaybleClaimSummaryComponent implements OnInit {

  @ContentChild(ClaimsummaryComponent)
  childRef!: ClaimsummaryComponent;
  constructor( private quantityService: CalculateQuantityService,
    private trailclaimadjudicationHelper: CheckCoveragePageService,
    private modalService: NgbModal,
    private epaProcessor: EpaprocessorService,
    private profileService: ProfileService,
    private logger: LoggerService,
    private memberSearchProcessor:MembersearchService,
    private prescriberSearch:PrescriberSearchService) { 
    
  }

  @Input() claimsummaryDetailsrequest!: ClaimSummaryDetails;
  @Input() claimresponse!: SelectedMedicine;
  @Output() removeevent = new EventEmitter<string>();
  @Output() reverseClaim = new EventEmitter<string>();
  @Output() updateevent = new EventEmitter<any>();
  @ViewChild("claimSummaryProgress")
  claimSummaryProgress?: ElementRef<HTMLElement>;
  @ViewChild("editProviderTemplate")
  editProviderTemplateRef!:ElementRef<HTMLElement>;
  editProviderStatus!:PRESCRIBERDETAIL_STATUS;

  @ViewChild("errorModalTemplate")
  errorModalTemplateRef!:ElementRef<HTMLElement>;
  errorMessage:string="";

  ePARunnerData!:EpaRunnerInitiationEvent;

  durProfessionalCodes:string[] = Array.from(DUR_PROFESSIONAL_SERVICECODES.keys());
  durResultCodes:string[] = Array.from(DUR_RESULTOF_SERVICECODES.keys());

  selectedMedicine: SelectedMedicine[]=[];
  targetrejectcode:any;
  targetTransitionRejectCode:any;
  counter = 1;
  trailAdjuducicationError = "";
  memberResponse: any;
  subscription: Subscription = new Subscription();
  blnChgQtyDOS = 0;
  changeQTYDOSForm = new FormGroup({
    Quantity: new FormControl('', { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(11), Validators.min(1)] }),
    Days: new FormControl('', { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(3), Validators.min(1)] }),
  });

  get Quantity() {
    return this.changeQTYDOSForm.get('Quantity')
  }

  get Days() {
    return this.changeQTYDOSForm.get('Days')
  }

  @ViewChild("overwriteConfirmModal")
  overwriteConfirmModal?: ElementRef<HTMLElement>;


  ngOnInit(): void {
    
    //console.log("Testing Data1:");
    //console.log(this.claimsummaryDetailsrequest);
    //console.log("Testing Data2:" + this.claimresponse);
    const memrequest: MemberDetailWebRequesthemi = new MemberDetailWebRequesthemi();
          memrequest.accountId = this.claimresponse.selectedMember.accountId;
          memrequest.id = this.claimresponse.selectedMember.memberId;
          memrequest.sourceSystemInstance = this.claimresponse.selectedMember.instanceId;
          memrequest.groupId = this.claimresponse.selectedMember.groupId;
          memrequest.carrierId = this.claimresponse.selectedMember.carrierId;
         this.trailclaimadjudicationHelper.getMemberDetailshemi(memrequest).then((memresponse:MemberDetailWebResponsehemi)=>{
          this.claimresponse.memberDetailResponse = memresponse;
           //console.log("memresponse",this.claimresponse.memberDetailResponse);
         });
    //  sessionStorage.getItem('selectedMedicineList');
    //sessionStorage.setItem("selectedMedicineList", JSON.stringify(this.selectedMedicine));
    //console.log(this.selectedMedicine);
    // <!-- For QA automation purpose -->
    // if(this.claimresponse.trailadjudicationwebresponse.response.rejectCode.length==0){
    //   this.targetrejectcode='Paid';
    // }else{
    //   this.targetrejectcode=this.claimresponse.trailadjudicationwebresponse.response.rejectCode;
    // }

    // if(this.claimresponse.trailadjudicationwebresponse.response.transitionBenefits!=undefined){
    //   this.claimresponse.trailadjudicationwebresponse.response.transitionBenefits.forEach((item:TransitionBenefit)=>{
    //     this.targetTransitionRejectCode.push(item.rejectedReason);
    //   });
    // }else{
    //   this.targetTransitionRejectCode=[];
    // }
    // <!-- End QA automation purpose -->


  }
  blnShowFullMedicationDetails:boolean=false;

  saveToDB = false;
  upadateOrReexecute(saveToDB: boolean = false) {
    if (this.Quantity?.valid && this.Days?.valid) {
      this.saveToDB = saveToDB;
      this.modalService.open(this.overwriteConfirmModal, { modalDialogClass: 'overwriteConfirmModal' });
    }
  }

  chgQtyDOS(saveToDB: boolean = false) {
    this.modalService.dismissAll();
    saveToDB = this.saveToDB;
    if (this.blnChgQtyDOS == 0) {
      this.blnChgQtyDOS = 1;
      this.Days?.setValue(""+this.claimsummaryDetailsrequest.DaysSupply);
      this.Quantity?.setValue(this.claimsummaryDetailsrequest.QuantityEntered);
    } else if(!saveToDB){
      this.blnChgQtyDOS = 0;
      this.logger.userAction("Changed Quantity/ Days");
      this.reexecute(parseInt(""+this.Days?.value),""+this.Quantity?.value);
    }
    if (saveToDB) {
      this.reexecute(parseInt(""+this.Days?.value), ""+this.Quantity?.value, saveToDB);
    }
  }


  reexecute(Days: number, Quantity: string, saveToDB: boolean = false) {
    //console.log("params", Days, Quantity);
    this.logger.userAction("Re-executing Claims");
    this.selectedMedicine = JSON.parse("" + sessionStorage.getItem('selectedMedicineList'));
    //console.log(this.selectedMedicine);
    this.selectedMedicine.forEach((item: SelectedMedicine) => {
      
      if (this.claimsummaryDetailsrequest.ProductID == item.drugSearchItem.productId) {
        item.Days = Days;
        item.Quantity = Quantity;
        let object = this.quantityService.quantityCalculate(parseFloat(Quantity), item.drugSearchItem);
        item.quantytyCalculate = parseFloat(object.qtycaliculated);
        item.quantityEntered = object.qtyEntered;
        item.quantytyDesc = object.qtydesc;
      }
    });

    this.modalService.open(this.claimSummaryProgress, { modalDialogClass: 'claimSummaryProgress' });
    this.trailclaimadjudicationHelper.checkCoverage(this.selectedMedicine).then((resp: SelectedMedicine[]) => {
      //console.log("data", resp);
      if (resp) {
        this.counter = 2;
        sessionStorage.setItem("selectedMedicineList", JSON.stringify(this.selectedMedicine));
        //console.log(this.selectedMedicine);
        const source = interval(2000);
        this.subscription = source.subscribe(() => {
          this.counter++;
          //console.log(this.counter);
          if (this.counter == 4) {
            //console.log(this.counter);
            this.subscription.unsubscribe();
            this.modalService.dismissAll();
            this.updateevent.emit(saveToDB);
          }
        });
      }
    },(error:any)=>{

    }).catch((error:any)=>{
      
    });
  }
  cancelQtyDOS() {
    this.blnChgQtyDOS = 0;
  }
  dismissAll() {
    this.modalService.dismissAll();
  }

  editProvider():void{
    this.logger.userAction("Editing Provider");  
    this.modalService.open(this.editProviderTemplateRef,{size:"lg",backdrop: 'static', keyboard: true});
  }
  isEditProviderValid(status:PRESCRIBERDETAIL_STATUS):void{
    this.editProviderStatus = status;
  }
  initiatePA():void{
    
    this.logger.userAction("Initiating PA.");
    this.epaProcessor.generatePaRefId(this.profileService.loginuser.UUID).then((response: PARefIDWebResponse)=>{
      this.submitPAInitiationRequest(response);
    }, (error:any)=>{
      this.modalService.open(this.errorModalTemplateRef,{size:'md'});
      if(error!=undefined && error.status!=undefined && error.status.statusDesc!=undefined){
        this.errorMessage = error.status.statusDesc;
      } else {
        this.logger.log("Error Occured while Initiating PA.");
        this.errorMessage = "An error occured. Please try again.";
      }
    }).catch((error:any)=>{
      this.logger.error("Error Occured while Initiating PA.");
      this.modalService.open(this.errorModalTemplateRef,{size:'md'});
      this.errorMessage = "An error occured. Please try again.";
    });
  }

  

  private submitPAInitiationRequest(paRef:PARefIDWebResponse):void{
    
    const ePARunnerStatus:EpaRunnerInitiationEvent = new EpaRunnerInitiationEvent();
    ePARunnerStatus.status = EPA_STATUS.PA_INITIATION;
    ePARunnerStatus.claimresponse = this.claimresponse;
    ePARunnerStatus.paRefId = paRef;
    this.epaProcessor.epaSubscription.next(ePARunnerStatus);
  }
}
