// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const contextpath="";

export const environment = {
  production: false,
  sso_url:'https://idx-stage.linkhealth.com/auth',
  sso_realm:'developer-platform',
  sso_clientId:'smap-ui',
  sso_contextpath:contextpath,
  sso_redirectUri:window.location.origin+contextpath+'/',

  membersearchIncludeLob: true,
  membersearchIncludeExcludeIndicator: 'I',
  membersearchGroupList:'SMAP',
  

  genericName: "",
  productIdQldr: "",
  instanceId:"BOOK2",
  excludeOnUnitDoseUnitUse:false,
  genericSubstitutionAllowed:false,
  forceGenericSubstitution:false,
  genericSubstitutionGPI:"",
  genericSubstitutionOTCCode:"",
  lob:"UHCEI",
  excludeOnNdcListNames:true,
  isSmapDrug:true,
  excludeOnActiveIndicator:false,



  paginationRecordsPerPage:10,
  EPA_POOLING_INTERVAL:10,
  EPA_WAITING_TIME_SEC:45,
  
  proxyurl: window.location.origin+contextpath+"/api",
  proxyurlFileUpload:window.location.origin+contextpath+"/api/upload",
  loginUrl: "rest/providerLogin/validateSmapUserProfile",
  orgUrl: "rest/providerLogin/validateSmapOrg",
  eventlogUrl: "rest/auditTracking/applEventLog",

  memberSearchUrl: "rest/memberSearch/getMemberSearchDetails",
  favouriteMembersUrl: "rest/memberSearch/getFavoriteMemberList?presbrId={presbrId}",
  addFavouriteMemberUrl: "rest/memberSearch/saveMemberFavorite",
  removeFavouriteMemberUrl: "rest/memberSearch/removeFavoriteMember?mbrSearchId={mbrSearchId}",
  submittedPAHistoryUrl: "rest/smapHistory/getSmapProviderPAHistory",
  submittedMemberPAHistoryUrl: "rest/smapHistory/getSmapMemberPAHistory",
  savedPAHistoryUrl: "rest/smapHistory/getSmapProviderPASavedHistory",
  //getMemberDetailsUrl: "rest/memberSearch/getMemberDetails",
  getMemberDetailsUrl: "rest/memberSearch/getMemberDetailshemi",
  savedMemberPAHistoryUrl: "rest/smapHistory/getSmapMemberPASavedHistory",
  prescriberSearchUrl: "rest/prescriber/getPrescriberSearch",
  prescriberDetailUrl: "rest/prescriber/getPrescriberDetails",
  generatePARefIdUrl: "rest/rxLinkPASTransactions/getPAReferenceID?uuid={UUID}",

  fullProgramDrugListDownloadUrl: "rest/smapClaims/downloadProductsSheet",
  fullProgramDrugListUrl: "rest/smapClaims/getFullProgramProductList",
  getSmapProductsByInfusionUrl: "rest/smapClaims/getProductListByInfsn?InfsnId={infsId}",
  updateSmapProductForInfusionUrl: "rest/smapClaims/infsnProductSaveOrUpdate",

  getProductListUrl: "rest/smapClaims/getSmapProductDetails",
  getSavedSerachesListUrl: "rest/memberSearch/getDrugFavorites?presbrId={presbrId}&loggedInUuid={UUID}",
  diagnosisCodeSearchUrl: "rest/diagnosis/diagnosisSearch",
  diagnosisDescriptionSearchUrl: "rest/diagnosis/diagnosisSearch",
  getDrugSearchDetailsListUrl: "rest/memberSearch/getDrugSearchDetails",
  getDrugSearchDetailsUrl: "rest/memberSearch/getDrugSearchDetails",
  unFavouriteDrugUrl: "rest/memberSearch/favOrUnfavDrug",
  favouriteDrugUrl: "rest/memberSearch/favOrUnfavDrug",

  getTrailClaimAdjudicationDetailsURL: "rest/trailClaimAdjudication/getTrailClaimAdjudicationDetails",
  getClaimHistoryByStatusesUrl: "rest/smapClaims/claimDetailsHistory",
  refreshUrl:"rest/smapClaims/multipleClaimAdvanceDetails",
  updateClaimStatus: "rest/smapClaims/updateClaimStatus",
  addOrRemoveClaimBookmarkUrl: "rest/smapClaims/addOrRemoveBookmark",
  getBookmarkedClaimsUrl: "rest/smapClaims/getBookmarkHistory",
  getSmapClaimTransactionByGroupId: "rest/smapClaims/getSmapClaimTransactionByGroupId?ClaimGrpId={claimGrpId}",
  getMultipleTrailClaimAdjudicationDetailsURL: "rest/trailClaimAdjudication/getMultipleTrailClaimAdjudicationDetails",
  getSaveMultipleTrailDetailUrl:"rest/smapClaims/saveMultipleSmapTrailClaim",
  getSubmitMultipleSmapClaimUrl:"rest/smapClaims/submitMultipleSmapClaim",
  getReverseClaimUrl:"rest/smapClaims/reversalSmapClaim",
  faxForm:"https://professionals.optumrx.com/prior-authorization.html#faxform",

  submitPAInitiationRequest: "rest/rxLinkPASTransactions/paInitiationRequest",
  paCancellationUrl: "rest/rxLinkPASTransactions/paCancelRequest",
  poolForPAInitiationResponseUrl: "rest/rxLinkPASTransactions/sendPAInitiationResponseJsonDB",
  poolForPACancellationResponseUrl :"rest/rxLinkPASTransactions/sendPACancelResponsseJson",
  getSavedPADetailsUrl: "rest/rxLinkPASTransactions/getSavedPaRequest?paReferenceID={parefId}&uuid={uuid}",
  getResubmitSavedPADetailsUrl:"rest/rxLinkPASTransactions/getPainitiationReSubmitDetails?paReferenceID={parefId}",
  submitOrSavePARequestUrl: "rest/rxLinkPASTransactions/paRequestSubmission",
  viewQuestionSetUrl: "rest/rxLinkPASTransactions/viewQuestionSet?paCaseID={paCaseID}",
  poolForPAResponseUrl: "rest/rxLinkPASTransactions/sendPAResponseJson",
 
  addFavouritePrescriberUrl:"rest/memberSearch/savePrescriberFavorite",
  removeFavouritePrescriberUrl:"rest/memberSearch/removeFavoritePrescriber?presbrFavId={presbrFavId}",
  favouritePrescribersUrl:"rest/memberSearch/getFavoritePrescriberList?presbrId={presbrId}&loggedInUuid={UUID}",

  saveForLaterUrl: "rest/rxLinkPASTransactions/paRequestSubmission",
  uploadFileUrl: "rest/rxLinkPASTransactions/paRequestAttachment?paReferenceID={parefId}",
  downloadFileUrl:"rest/rxLinkPASTransactions/getAttachmentAsBytes?paAttachmentID={paAttachId}"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
